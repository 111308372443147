




























import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  contentStatus,
  contentStatusButtonVariant,
  contentType
} from '@/config/content/contentStatus'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import { userMapper } from '@/store/modules/user'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['isTeacher', 'isContentManager'])
  }
})
@Component
export default class ContentFilterButtonGroup extends Mapper {
  @Prop(String) typeOfContent!: string

  private contentStatus_ = contentStatus
  private contentStatusButtonVariant_ = contentStatusButtonVariant
  private contentType_ = contentType

  private filterStatus: contentStatus = contentStatus.all

  private viewContentManagerButtons = false
  private viewTeacherButton = false

  private created() {
    const ability = PermissionManager.common()
    this.viewContentManagerButtons = ability.can(
      permissions.viewContentManagerButtons,
      undefined
    )
    this.viewTeacherButton = ability.can(
      permissions.viewMyEventsButton,
      undefined
    )
  }

  private isPressButton(buttonStatus: contentStatus) {
    if (buttonStatus === this.filterStatus) {
      return this.contentStatusButtonVariant_.pressed
    } else {
      return this.contentStatusButtonVariant_.notPressed
    }
  }

  private onButtonClick(status: contentStatus) {
    this.filterStatus = status
    this.$emit('onButtonClick', status)
  }
}
