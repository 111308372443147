























































import { Component, Prop, Vue } from 'vue-property-decorator'
import LikeEditor from '@/components/content/helpers/LikeEditor.vue'
import { imagePreview } from '@/config/default/defaultImages'
import { ContentAdapter } from '@/api/adapters/content'
import { OrganizationAdapter } from '@/api/adapters/organizations'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import {
  contentStatusBadges,
  contentStatusTranslation,
  contentType
} from '@/config/content/contentStatus'
import _ from 'lodash'
import { userMapper } from '@/store/modules/user'
import Config from '@/config/default/config'
import { Content } from '@/types/interfaces/content'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['isTeacher', 'userOrganizations'])
  }
})

@Component({
  components: { LikeEditor }
})
export default class ContentPreview extends Mapper {
  @Prop(Object) content!: Content
  @Prop({ type: Boolean, default: true }) readonly showLikesViews!: boolean
  @Prop({ type: Boolean, default: false }) readonly isMoodlePreview!: boolean

  private canViewContentStatus = false
  private hover = false
  private imagePreview_ = imagePreview
  private contentStatusTranslation_ = contentStatusTranslation
  private contentStatusBadges_ = contentStatusBadges
  $refs!: {
    previewImage: HTMLImageElement
  }

  private previewHeight = 80

  private created() {
    const ability = PermissionManager.common()
    this.canViewContentStatus = ability.can(
      permissions.viewContentStatus,
      undefined
    )

    if (this.isMoodlePreview) {
      this.content.avatarUrl = this.content?.avatarUrl ?? imagePreview
    } else {
      this.content.avatarUrl = ContentAdapter.adaptAvatarUrl(
        this.content.avatarUrl
      )
      if (this.$route.path != Config.routing.homePage.path) {
        this.content = OrganizationAdapter.adaptOrganization(this.content)
      }
      if (this.content.eventDate) {
        this.content.eventDate = ContentAdapter.changeDateFormat(
          this.content.type,
          this.content.eventDate
        )
      }
    }
  }
  private onPreviewLoad(event: any) {
    this.previewHeight = event.target.clientWidth * 0.5625 // 0.5625 = 9/16 => Соотношение сторон: 16:9
  }

  private onHover(hovered: boolean) {
    this.hover = hovered
  }

  private onPreviewClick() {
    if (this.content.type === contentType.news) {
      this.$router.push(`/news/${this.content.id}`)
    } else if (this.content.type === contentType.event) {
      this.$router.push(`/events/${this.content.id}`)
    }

    if (this.isMoodlePreview) {
      window.open(this.content.link, '_blank')
    }
  }

  private get isMyEvent() {
    return (
      _.intersectionBy(this.userOrganizations, this.content.organizations, 'id')
        .length > 0
    )
  }
}
