import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'

@Component
export default class PaginationMixin extends Vue {
  private limit = 12
  private offset = 0
  private isShowMore = false

  private contentList: object[] = []

  public resetPagination() {
    this.contentList = []
    this.offset = 0
  }

  public async fetchNext(fetchFunction: Function, type: string) {
    if (_.isNil(fetchFunction)) {
      return this.contentList
    }

    const response = (await fetchFunction(this.limit, this.offset, type)).data

    this.contentList = [...this.contentList, ...response.rows]
    this.offset += this.limit
    this.isShowMore = response.count > this.offset

    return this.contentList
  }
}
