



















































import { Component, Vue } from 'vue-property-decorator'
import ContentPreview from '@/components/content/ContentPreview.vue'
import ContentFilterButtonGroup from '@/components/common/buttons/ContentFilterButtonGroup.vue'
import ContentAPI from '@/api/requests/content'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import PaginationMixin from '@/mixins/paginationMixin'
import { contentStatus, contentType } from '@/config/content/contentStatus'

@Component({
  components: {
    ContentPreview,
    ContentFilterButtonGroup
  },
  mixins: [PaginationMixin]
})
export default class AllEvents extends Vue {
  private eventsData: object[] = []
  private showSpinner = true
  private nothingToDisplay = false
  private contentStatus_ = contentStatus
  private status = this.contentStatus_.all
  private contentType_ = contentType

  private isShowMore!: boolean
  private fetchNext!: Function
  private resetPagination!: Function

  private viewContentManagerButtons = false
  private viewTeacherButton = false

  private async filterStatusChanged(filterStatus: any) {
    this.status = filterStatus
    this.eventsData = []
    this.resetPagination()
    await this.showMore()
  }

  private async showMore() {
    this.showSpinner = true
    this.isShowMore = false

    this.eventsData = await this.fetchNext(
      ContentAPI.getEvents,
      this.viewContentManagerButtons || this.viewTeacherButton
        ? this.status
        : this.contentStatus_.publicated
    )
    this.showSpinner = false
    this.nothingToDisplay = this.eventsData.length === 0
  }

  private async created() {
    const ability = PermissionManager.common()
    this.viewContentManagerButtons = ability.can(
      permissions.viewContentManagerButtons,
      undefined
    )
    this.viewTeacherButton = ability.can(
      permissions.viewMyEventsButton,
      undefined
    )
    this.showMore()
  }
  private onAddEventClick() {
    this.$router.push('/events/new')
  }
}
