import { http } from '@/api/requests/httpAxios'
import { AxiosPromise } from 'axios'
import createURLParams from '@/api/datasource/createURLParams'
import { contentStatus } from '@/config/content/contentStatus'
import { ContentAdapter } from '@/api/adapters/content'

export default class ContentAPI {
  public static getSingleEvent(pageId: number): AxiosPromise {
    return http.get(`/events/${pageId}`, {
      validateStatus: status => status < 500
    })
  }

  public static getEvents(
    limit: number,
    offset: number,
    status: string
  ): AxiosPromise {
    const params = createURLParams(limit, offset)
    params.append('status', status)
    return http.get('/events', { params })
  }

  public static getSingleNews(pageId: number): AxiosPromise {
    return http.get(`/news/${pageId}`, {
      validateStatus: status => status < 500
    })
  }

  public static getNews(
    limit: number,
    offset: number,
    status: string
  ): AxiosPromise {
    const params = createURLParams(limit, offset)
    params.append('status', status)
    return http.get('/news', { params })
  }

  public static async addContent(data: object, status: contentStatus) {
    return http.post(
      '/content',
      await ContentAdapter.formatContentData(data, status),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }

  public static async editContent(
    id: number,
    data: object,
    status: contentStatus
  ) {
    return http.put(
      `/content/${id}`,
      await ContentAdapter.formatContentData(data, status),
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }

  public static deleteContent(id: number): AxiosPromise {
    return http.delete(`/content/${id}`)
  }

  public static editReaction(
    id: number,
    likeState: string | null
  ): AxiosPromise {
    return http.put(`/content/${id}/reaction`, { likeState })
  }

  public static addVoteOnEvent(id: number, voteData: any): AxiosPromise {
    return http.post(
      `/content/${id}/vote`,
      { voteData },
      {
        validateStatus: status => !!status
      }
    )
  }

  public static getUserVote(eventId: number): AxiosPromise {
    return http.get(`/events/${eventId}/vote`)
  }

  public static getLastFiveCourses(): AxiosPromise {
    return http.get('/moodle/courses/last5')
  }
}
