






























import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

import ContentAPI from '@/api/requests/content'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'

@Component
export default class LikeEditor extends Vue {
  @Prop(Number) contentId!: number
  @Prop(Number) likes!: number
  @Prop(Number) dislikes!: number
  @Prop() clicked?: true | false | null

  private canSeeViewsLikes = false
  private viewDislikesCount = false

  private likes_ = 0
  private dislikes_ = 0
  private clicked_: 'like' | 'dislike' | null = null

  private likeHover = false
  private dislikeHover = false

  private created() {
    const ability = PermissionManager.common()
    this.viewDislikesCount = ability.can(
      permissions.viewDislikesCount,
      undefined
    )
    this.canSeeViewsLikes = ability.can(permissions.seeViewsLikes, undefined)

    this.likes_ = this.likes
    this.dislikes_ = this.dislikes

    if (this.clicked === true) {
      this.clicked_ = 'like'
    } else if (this.clicked === false) {
      this.clicked_ = 'dislike'
    } else {
      this.clicked_ = null
    }
  }

  private onLikeHover(hovered: boolean) {
    this.likeHover = hovered
  }

  private onDislikeHover(hovered: boolean) {
    this.dislikeHover = hovered
  }

  private async onLikeClicked() {
    if (this.clicked_ === 'like') {
      this.likes_--
      this.clicked_ = null
    } else if (this.clicked_ === 'dislike') {
      this.likes_++
      if (!_.isNil(this.dislikes_)) {
        this.dislikes_--
      }
      this.clicked_ = 'like'
    } else {
      this.likes_++
      this.clicked_ = 'like'
    }
    await ContentAPI.editReaction(this.contentId, this.clicked_)
  }

  private async onDislikeClicked() {
    if (this.clicked_ === 'like') {
      this.likes_--
      if (!_.isNil(this.dislikes_)) {
        this.dislikes_++
      }
      this.clicked_ = 'dislike'
    } else if (this.clicked_ === 'dislike') {
      if (!_.isNil(this.dislikes_)) {
        this.dislikes_--
      }
      this.clicked_ = null
    } else {
      if (!_.isNil(this.dislikes_)) {
        this.dislikes_++
      }
      this.clicked_ = 'dislike'
    }
    await ContentAPI.editReaction(this.contentId, this.clicked_)
  }
}
